import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LinkCustom from "../components/linkAndButtons/linkCustom";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
        <section
            className="flex flex-col xl:flex-row xl:justify-between h-full mx-6 md:mx-16 lg:mx-32 xl:mx-32">
            <div className="description mb-24 xl:mt-24">
                <h1 className="text-4xl font-bold mb-1">
                    ¡Lo siento! La página no se pudo encontrar
                </h1>
                <p className="text-2xl mb-8 w-full md:w-2/3">
                    Haga clic en el botón de abajo para volver a la página principal.
                </p>
                <LinkCustom
                    to="/">
                    <span> Volver a la página principal</span>
                </LinkCustom>
            </div>
        </section>
    </Layout>
  );
}

export default NotFoundPage;
